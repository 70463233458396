@import "./variables.scss";

.mySwiper {
    width: 80vw;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
}

.mySwiperSlide {
    align-items: center;
    padding: 20px;
    opacity: 1;
}

.mySwiperSlide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
}

.storeDetails {
    margin-right: 20px;
}

.btn {
    background-color: $sea-green;
}

.btn:hover {
    background-color: $dark-green;
}
