@import "./variables.scss";

.swiper {
  width: 60vw;
  height: 60vh;
  border: 5px solid $dark-green;
  border-radius: $default-radius;
  margin-left: 20vw;
  margin-right: 20vw;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  background-size: cover;
  background-position: center;
}

.mySwiper1 {
  margin-top: 20px;
  height: 20vh;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper1 .swiper-slide {
  width: 25%;
  height: 90%;
  opacity: 0.4;
}

.mySwiper1 .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper2 {
  height: 60vh;
  width: 60vw;
  object-position: center;
}

.mySwiper2 {
  margin-top: 20px;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper2 .swiper-slide {
  width: 60vw;
  height: 90%;
  opacity: 1;
}

img {
  opacity: 1;
}

.myGalleryImg {
  display: block;
  border: 3px solid $sea-green;
  border-radius: $default-radius;
  margin-left: auto;
  margin-right: auto;
}

.myThumbnail {
  width: 15vw;
  height: 15vh;
  border: 3px solid $sea-green;
  border-radius: $default-radius;
}

.verticalText {
  writing-mode: vertical-rl;
  text-orientation: upright;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 85px;
}

.Gallery {
  display: flex;
  margin-left: 15px;
}

.GallerySwipers {
  flex-grow: 2;
}

.GalleryText {
  flex-grow: 1;
}
