.card {
    margin-left: 5vw;
    margin-right: 5vw;
}

.card-header {
    font-size: 3vw;
}

.card-text {
    font-size: 2vw;
}