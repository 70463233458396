@import "custom";
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

.sticky-nav {
  position: sticky;
  top: 0;
  font-size: xx-large;
}

.artAysiaApp {
  background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(../assets/aysiaEaston_clear.png), url(../assets/paper-background.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  font-family: 'Dancing Script', cursive;
}

.aysiaNavBrand {
  font-size: xx-large;
}

img {
  opacity: 0.1;
}