.mainText {
    text-align: center;
    padding-top: 20vh;
}

.title {
    font-size: 10vw;
}

.subtitle {
    font-size: 7vw;
}